<template>
  <v-row>
    <v-col
      cols="12"
      md="4"
      sm="6"
    >
      <upcoming-bond-interest-card
        :is-loading="isUpcomingInterestDataLoading"
        :interest-amount="upcomingBondInterest.interest_amount"
        :interest-date="upcomingBondInterest.date"
      />
    </v-col>
  </v-row>
</template>

<script>
import axios from 'axios'
import UpcomingBondInterestCard from '@/views/dashboard/UpcomingBondInterestCard.vue'

export default {
  components: {
    UpcomingBondInterestCard,
  },
  data() {
    return {
      isUpcomingInterestDataLoading: false,
      upcomingBondInterest: {
        interest_amount: 0,
        date: '01-01-2021',
      },
    }
  },
  mounted() {
    this.getUpcomingInterestData()
  },
  methods: {
    getUpcomingInterestData() {
      this.isUpcomingInterestDataLoading = true
      axios.get('/analytics/bonds/upcoming-interest-data?limit=1')
        .then(response => {
          if (response.data && response.data.length > 0) {
            this.upcomingBondInterest = response.data[0]
          }
        })
        .catch(error => {
          console.error(error)
        })
        .finally(() => {
          this.isUpcomingInterestDataLoading = false
        })
    },
  },
}
</script>
