<template>
  <v-card>
    <v-card-title class="align-start">
      <span>Upcoming Bond Interest</span>
      <v-spacer></v-spacer>
    </v-card-title>

    <v-card-text
      v-if="isLoading"
      class="my-7"
    >
      <vue-skeleton-loader
        v-if="isLoading"
        class="mt-4"
        :width="164"
        :height="40"
      />
      <vue-skeleton-loader
        v-if="isLoading"
        class="mt-4"
        :width="120"
        :height="15"
      />
      <vue-skeleton-loader
        class="mt-4"
        :width="319"
        :height="38"
      />
    </v-card-text>

    <v-card-text
      v-else
      class="my-7"
    >
      <div v-if="interestAmount > 0">
        <div
          class="d-flex align-center mb-2"
        >
          <h1 class="text-4xl font-weight-semibold">
            {{ interestAmount | formatCurrency }}
          </h1>
        </div>
        <span
          class="font-weight-medium"
        >
          On {{ interestDate | formatDateHuman }}
        </span>
        <v-btn
          block
          color="primary"
          class="mt-6"
          outlined
          @click="onViewMoreClick"
        >
          View More
        </v-btn>
      </div>
      <div v-else>
        <span
          class="font-weight-medium"
        >
          No upcoming interest
        </span>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import VueSkeletonLoader from 'skeleton-loader-vue'

export default {
  components: {
    VueSkeletonLoader,
  },
  props: {
    isLoading: {
      type: Boolean,
      required: true,
    },
    interestAmount: {
      type: [Number, String],
      required: true,
    },
    interestDate: {
      type: String,
      required: true,
    },
  },
  methods: {
    onViewMoreClick() {
      this.$router.push({ name: 'bonds-analytics', hash: '#upcoming-interest' })
    },
  },
}
</script>
